'use strict';

window.console || (window.console = {
	log: function log() {}
});
var FUNCTIONS = window.FUNCTIONS || null;
var cssua = window.cssua || null;

(function ($) {
	var siteName = '';
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.0\\.|192\\.168\\.11\\.|192\\.168\\.1\\.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						// console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});

			var $checkswitch = $('.js-check-toggle');

			$checkswitch.on('click', function () {
				$(this).toggleClass(a);
				$(this).parents('.js-check-toggleWrap').find('.js-check-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
						pos -= 60;
					} else {
						pos -= 90;
					}
				}
				if (!(FUNCTIONS.va.window.width < 1024)) {
					$('.header-nav-content-li-in').removeClass(v);
					$('.header-nav-content-li_menu').stop().slideUp(400);
					$('.__block02').removeClass(v);
					$('.__block03').removeClass(v);
					$('.__block03').removeClass('is-visible2');
					$('.js-header-block01btn').removeClass(v);
					$('.js-header-block01content').removeClass(v);
					$('.js-header-block02btn').removeClass(v);
					$('.js-header-block02content').removeClass(v);
					$('.__member .js-toggle').removeClass(a);
					$('.__member .js-toggleBlock').css('display', 'none');
				}
				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		pageTop: function pageTop() {
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var $footer = $('.footer');
				// var footerPos = $footer.offset().top + $footer.height();
				var thisScroll = $this.scrollTop();
				// var pagetopPos = $this.height() + $footer.height() + thisScroll;

				if (thisScroll > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}
			});
		},
		headerMenu: function headerMenu() {
			var $menu = $('.js-header-menu');
			var $menucontent = $('.js-header-menu-content');
			var flag = false;
			var scrollTopNow;

			// ハンバーガーメニュー
			$menu.on('click', function () {
				if (!flag) {
					scrollTopNow = document.body.scrollTop + document.documentElement.scrollTop;
					$menu.addClass(a);
					$menucontent.addClass(a);
					$('.container').css({
						position: 'fixed',
						width: '100%',
						top: -1 * scrollTopNow,
						left: 0
					});
					$('body').addClass(f);
					$('.js-toggle').removeClass(a);
					$('.js-toggleBlock').css('display', 'none');
					flag = true;
				} else {
					$menu.removeClass(a);
					$menucontent.removeClass(a);
					$('.container').css({
						position: 'static'
					});
					$('body').removeClass(f);
					$(window).scrollTop(scrollTopNow);
					$('.header-nav-content-li-in').removeClass(v);
					$('.js-toggle').removeClass(a);
					$('.js-toggleBlock').css('display', 'none');
					$('.header-nav-content-li_menu').stop().slideUp(400);
					flag = false;
				}
			});
			$('a[rel="scroll"]').on('click', function (event) {
				$menu.removeClass(a);
				$menucontent.removeClass(a);
				$('.container').css({
					position: 'static'
				});
				$('body').removeClass(f);
				$(window).scrollTop(scrollTopNow);
				$('.header-nav-content-li-in').removeClass(v);
				$('.js-toggle').removeClass(a);
				$('.js-toggleBlock').css('display', 'none');
				$('.header-nav-content-li_menu').stop().slideUp(400);
				flag = false;
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
						pos -= 60;
					} else {
						pos -= 90;
					}
				}
				if (!(FUNCTIONS.va.window.width < 1024)) {
					$('.header-nav-content-li-in').removeClass(v);
					$('.header-nav-content-li_menu').stop().slideUp(400);
					$('.__block02').removeClass(v);
					$('.__block03').removeClass(v);
					$('.__block03').removeClass('is-visible2');
					$('.js-header-block01btn').removeClass(v);
					$('.js-header-block01content').removeClass(v);
					$('.js-header-block02btn').removeClass(v);
					$('.js-header-block02content').removeClass(v);
					$('.__member .js-toggle').removeClass(a);
					$('.__member .js-toggleBlock').css('display', 'none');
				}
				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		headerMegaMene: function headerMegaMene() {
			var $btn = $('.header-nav-content-li-in');
			var $item = $('.header-nav-content-li_menu');
			var $nav = $('.header-nav');
			var $memberToggle = $('.__member .js-toggle');
			var $memberToggleBlock = $('.__member .js-toggleBlock');
			if (!(FUNCTIONS.va.window.width < 1024)) {
				$btn.on('click', function () {
					$btn.removeClass(v);
					$item.stop().slideUp(300);
					$('.__block02').removeClass(v);
					$('.__block03').removeClass(v);
					$('.__block03').removeClass('is-visible2');
					$('.js-header-block01btn').removeClass(v);
					$('.js-header-block01content').removeClass(v);
					$('.js-header-block02btn').removeClass(v);
					$('.js-header-block02content').removeClass(v);
					$memberToggle.removeClass(a);
					$memberToggleBlock.css('display', 'none');
					if (!$('.header-nav-content-li-in.is-active').length) {
						$(this).addClass(a);
						$(this).addClass(v);
						$(this).next('.header-nav-content-li_menu').stop().slideDown(300);
					} else {
						if (!$(this).hasClass(a)) {
							$btn.removeClass(a);
							$(this).addClass(a);
							$(this).addClass(v);
							$(this).next('.header-nav-content-li_menu').stop().slideDown(300);
						} else {
							$btn.removeClass(a);
						}
					}
				});
				$('.header-nav-content-li_menu a').on('click', function () {
					$btn.removeClass(v);
					$item.stop().slideUp(300);
					$('.__block02').removeClass(v);
					$('.__block03').removeClass(v);
					$('.__block03').removeClass('is-visible2');
					$('.js-header-block01btn').removeClass(v);
					$('.js-header-block01content').removeClass(v);
					$('.js-header-block02btn').removeClass(v);
					$('.js-header-block02content').removeClass(v);
					$memberToggle.removeClass(a);
					$memberToggleBlock.css('display', 'none');
					$btn.removeClass(a);
				});
				$('.contents').on('click', function () {
					$btn.removeClass(v);
					$item.stop().slideUp(300);
					$btn.removeClass(a);
				});
				$('.headerWrap').on('click', function () {
					$btn.removeClass(v);
					$item.stop().slideUp(300);
					$btn.removeClass(a);
				});
				// $nav.on('mouseleave', function(){
				// 	$btn.removeClass(v);
				// 	$item.stop().slideUp(400);
				// 	$('.__block02').removeClass(v);
				// 	$('.__block03').removeClass(v);
				// 	$('.__block03').removeClass('is-visible2');
				// 	$('.js-header-block01btn').removeClass(v);
				// 	$('.js-header-block01content').removeClass(v);
				// 	$('.js-header-block02btn').removeClass(v);
				// 	$('.js-header-block02content').removeClass(v);
				// });
			} else {
				$btn.on('click', function () {
					$(this).toggleClass(v);
					$(this).next('.header-nav-content-li_menu').stop().slideToggle(400);
				});
			}
			// 三段階メニュー

			var _loop = function _loop(l) {
				var $block01btn = $('.__blockWrap').eq(l).find($('.js-header-block01btn'));
				var $block01item = $('.__blockWrap').eq(l).find($('.js-header-block01content'));
				var $block02btn = $('.__blockWrap').eq(l).find($('.js-header-block02btn'));
				var $block02item = $('.__blockWrap').eq(l).find($('.js-header-block02content'));
				if (!(FUNCTIONS.va.window.width < 1024)) {
					var _loop2 = function _loop2(i) {
						$block01btn.eq(i).on('mouseenter', function () {
							$block01btn.removeClass(v);
							$block01item.removeClass(v);
							$block02btn.removeClass(v);
							$block02item.removeClass(v);
							$('.__block03').removeClass(v);
							if (!$block01item.eq(i).text().replace(/^\s+|\s+$/g, '')) {
								$('.__block02').removeClass(v);
								$('.__block03').removeClass('is-visible2');
							} else {
								$('.__block02').addClass(v);
								$('.__block03').addClass('is-visible2');
							}
							$block01btn.eq(i).addClass(v);
							$block01item.eq(i).addClass(v);
						});
					};

					for (var i = 0; i < $block01btn.length; i++) {
						_loop2(i);
					}

					var _loop3 = function _loop3(i) {
						$block02btn.eq(i).on('mouseenter', function () {
							$block02btn.removeClass(v);
							$block02item.removeClass(v);
							if (!$block02item.eq(i).text().replace(/^\s+|\s+$/g, '')) {
								$('.__block03').removeClass(v);
								$('.__block03').addClass('is-visible2');
							} else {
								$('.__block03').addClass(v);
								$('.__block03').removeClass('is-visible2');
							}
							$block02btn.eq(i).addClass(v);
							$block02item.eq(i).addClass(v);
						});
					};

					for (var i = 0; i < $block02btn.length; i++) {
						_loop3(i);
					}
				}
			};

			for (var l = 0; l < $('.__blockWrap').length; l++) {
				_loop(l);
			}
			// 検索部分
			var $searchBoxBtn = $('.js-searchToggle');
			var $searchBoxContent = $('.js-searchToggleBlock');
			var $searchBoxBack = $('.js-searchToggleBlockBack');
			var $headerRight = $('.header-right');
			$searchBoxBtn.on('click', function () {
				$searchBoxContent.toggleClass(a);
				$searchBoxBack.toggleClass(a);
				$headerRight.toggleClass('is-hidden');
			});
			$searchBoxBack.on('click', function () {
				$searchBoxContent.removeClass(a);
				$searchBoxBack.removeClass(a);
				$headerRight.removeClass('is-hidden');
			});
		},
		search: function search() {
			var $freewordSerchBtn = $('.js-freeword-search-btn');
			var $freewordSerchInput = $('.js-freeword-search-input');
			// $($freewordSerchInput).change(function(){
			// 	$freewordSerchBtn.eq(0).attr('href', '/search/?p_keyword=' + encodeURI($(this).val()));
			// 	$freewordSerchBtn.eq(1).attr('href', '/search/?p_keyword=' + encodeURI($(this).val()));
			// });

			// 検索部分でエンターを押したときにボタンクリックと同じ処理を追加
			if (!($(window).width() < 1025)) {
				$($freewordSerchInput).keypress(function (e) {
					if (e.which === 13) {
						window.location.href = '/search/?p_keyword=' + encodeURI($(this).val());
						return false;
					}
				});
			}
		},
		sideNav: function sideNav() {
			if ($('.js-sideNav-item').length) {
				$('.js-sideNav-item').each(function (idx, val) {
					if ($(val).attr('href') === location.pathname) {
						$(val).addClass(a);
					}
				});
			}
		},
		jsMatchHeight: function jsMatchHeight() {
			if ($('.__title').length) {
				$('.__title').matchHeight();
			}
			if ($('.index-movie-item h3').length) {
				$('.index-movie-item h3').matchHeight();
			}
			if ($('.seminar-box01 h3').length) {
				$('.seminar-box01 h3').matchHeight();
			}
		},
		haederMypageMenu: function haederMypageMenu() {
			var baseURL = '';
			if (document.domain === 'review.cmsod.jp') {
				baseURL = 'https://staging.api.saponet.mynavi.jp';
			}
			$.ajax({
				method: 'get',
				url: baseURL + '/mypage/my_info/'
			}).then(function (response) {
				// console.log(response,'header-login');
				if ($('.js-download-link').length) {
					for (var l = 0; l < $('.js-download-link').length; l++) {
						$('.js-download-link').eq(l).attr('target', '_blank');
					}
				}
				if (!response.login) {
					$('.header-right').removeClass('is-login');
					$('.header-right').addClass('is-logoff');
					$('.header-logoutBtnWrap').removeClass('is-login');
					if ($('.js-download-link').length) {
						for (var i = 0; i < $('.js-download-link').length; i++) {
							$('.js-download-link').eq(i).attr('href', '/account/login/');
						}
					}
					var isRestricted = $('.bclo-only-member').length > 0;
					var blocks = $('.bclo-detail');
					if (isRestricted) {
						var targetIdx = null;

						blocks.each(function (idx, el) {
							if ($(el).find('.bclo-only-member').length !== 0) {
								targetIdx = idx;
								$('.bclo-only-member').removeClass('di_none');
							}
							if (targetIdx && idx > targetIdx) {
								$(el).addClass('di_none');
							}
						});
					}
					// お役立ち資料ダウンロードリンク
					if ($('.js-files-download-btn').length) {
						for (var _l = 0; _l < $('.js-files-download-btn').length; _l++) {
							$('.js-files-download-btn').eq(_l).attr('href', '/account/login/');
							$('.js-files-download-btn').eq(_l).attr('target', '_self');
							$('.js-files-download-btn').eq(_l).removeClass('di_none');
						}
					}
				} else {
					if (document.domain === 'saponet.mynavi.jp') {
						if ($('#_karte_id').length) {
							$('#_karte_id').text(response.hashed);
							if (typeof krt === 'function') {
								krt("send", "attribute", {
									"saponet_userId": response.hashed
								});
							}
						}
					}
					$('.header-right').addClass('is-login');
					$('.header-right').removeClass('is-logoff');
					$('.header-logoutBtnWrap').addClass('is-login');
					$('.header-right-li-firstName').text(response.first_name);
					$('.header-right-li-lastName').text(response.last_name);
					if (response.unread) {
						if (!(response.unread == 0)) {
							$('.header-right-li-notice-link .__num').html(response.unread);
							$('.header-right-li-notice-link .__num').removeClass('di_none');
						}
					}
					if (response._token) {
						$('input[name="_token"]').val(response._token);
					}
					// 記事ダウンロードボタン部分
					if ($('.js-download-link').length) {
						$('.js-download-link').on('click', function () {
							var linkurl = $(this).attr('href');
							var linktext = $(this).data('filename');
							// console.log(linkurl,linktext);
							$.ajax({ url: baseURL + '/contact/files/download/?name=' + linktext + '&url=' + linkurl });
						});
					}
					// お役立ち資料ダウンロードリンク
					if ($('.js-files-download-btn').length) {
						for (var _l2 = 0; _l2 < $('.js-files-download-btn').length; _l2++) {
							$('.js-files-download-btn').eq(_l2).removeClass('di_none');
						}
					}
					// お役立ち資料ダウンロード時マルケト送信
					$('.js-files-download-btn').on('click', function () {
						var linkurl = $(this).attr('href');
						var linktext = $('.rt_cf_d_title').eq(0).text();
						$.ajax({ url: baseURL + '/contact/files/download/?name=' + linktext + '&url=' + linkurl.split(/[?#]/)[0] });
					});
					// アクティビティ
					document.cookie = "_AC_LOG_TITLE=" + encodeURI(document.title) + "; path=/; SameSite=Lax; Max-Age=100; Secure";
					$.ajax({ url: '/mypage/log/' });
				}
			}).catch(function (err) {
				if ($('.js-download-link').length) {
					for (var l = 0; l < $('.js-download-link').length; l++) {
						$('.js-download-link').eq(l).attr('target', '_blank');
					}
				}
				// お役立ち資料ダウンロードリンク
				if ($('.js-files-download-btn').length) {
					for (var _l3 = 0; _l3 < $('.js-files-download-btn').length; _l3++) {
						$('.js-files-download-btn').eq(_l3).attr('href', '/account/login/');
						$('.js-files-download-btn').eq(_l3).attr('target', '_self');
						$('.js-files-download-btn').eq(_l3).removeClass('di_none');
					}
				}
				$('.header-right').removeClass('is-login');
				$('.header-right').addClass('is-logoff');
				if ($('.js-download-link').length) {
					for (var i = 0; i < $('.js-download-link').length; i++) {
						$('.js-download-link').eq(i).attr('href', '/account/login/');
					}
				}
				var isRestricted = $('.bclo-only-member').length > 0;
				var blocks = $('.bclo-detail');
				if (isRestricted) {
					var targetIdx = null;

					blocks.each(function (idx, el) {
						if ($(el).find('.bclo-only-member').length !== 0) {
							targetIdx = idx;
							$('.bclo-only-member').removeClass('di_none');
						}
						if (targetIdx && idx > targetIdx) {
							$(el).addClass('di_none');
						}
					});
				}
			});
		},
		contactFloatLink: function contactFloatLink() {
			if ($('.js-contactLink').length) {
				if (location.pathname.split('/')[1] === 'service_saiyo') {
					$('.js-contactLink').attr('href', '/contact/saiyo/');
				}
				if (location.pathname.split('/')[1] === 'service_career') {
					$('.js-contactLink').attr('href', '/contact/career/');
				}
			}
		},
		marsflag: function marsflag() {
			var el = document.createElement('script');
			if (location.pathname === '/search.html') {
				// マーズフラッグ検索結果用js
				el.src = '//c.marsflag.com/mf/mfx/1.0-latest/js/mfx-serp.js';
			} else {
				// マーズフラッグ検索窓用js
				el.src = '//c.marsflag.com/mf/mfx/1.0-latest/js/mfx-sbox.js';
			}
			setTimeout(function () {
				document.body.appendChild(el);
			}, 100);
		},
		tooltip: function tooltip() {
			// if($(window).width() < 1024) {
			// 	$('._numWrap ._num').on('click', function(){
			// 		console.log('._tooltip','click');
			// 		if(!$('._numWrap ._num').next('._tooltip').hasClass(a)) {
			// 			$('._numWrap ._num').next('._tooltip').addClass(a);
			// 		} else {
			// 			$('._numWrap ._num').next('._tooltip').removeClass(a);
			// 		}
			// 	});
			// } else {
			// 	$('._numWrap ._num').on('mouseenter', function(){
			// 		console.log('._tooltip','hover');
			// 		$('._numWrap ._num').next('._tooltip').addClass(a);
			// 	});
			// 	$('._numWrap ._num').on('mouseleave', function(){
			// 		$('._numWrap ._num').next('._tooltip').removeClass(a);
			// 	});
			// }
		},
		seminarDetailPage: function seminarDetailPage() {
			// すべて選択・すべて解除部分
			if ($('.js-seminar-detail-allCheck').length) {
				$('.js-seminar-detail-allCheck').find('input[type="checkbox"]').change(function (e) {
					if (!$(e.currentTarget).prop('checked')) {
						$(e.currentTarget).parents('.js-seminar-detail-allCheck').find('._on').removeClass('di_none');
						$(e.currentTarget).parents('.js-seminar-detail-allCheck').find('._off').addClass('di_none');
						for (var i = 0; i < $('.js-seminar-detail-sessionCheck').find('input[type="checkbox"]').length; i++) {
							$('.js-seminar-detail-sessionCheck').find('input[type="checkbox"]').eq(i).prop("checked", false);
						}
					} else {
						$(e.currentTarget).parents('.js-seminar-detail-allCheck').find('._off').removeClass('di_none');
						$(e.currentTarget).parents('.js-seminar-detail-allCheck').find('._on').addClass('di_none');
						for (var _i = 0; _i < $('.js-seminar-detail-sessionCheck').find('input[type="checkbox"]').length; _i++) {
							$('.js-seminar-detail-sessionCheck').find('input[type="checkbox"]').eq(_i).prop("checked", true);
						}
					}
				});
			}
			// submitボタンアクティブ部分
			if ($('.js-seminar-detail-submit').length) {
				var valCheck = function valCheck() {
					var flag = true;
					var count = 0;
					for (var i = 0; i < $('.js-seminar-detail-sessionCheck').find('input[type="checkbox"]').length; i++) {
						if ($('.js-seminar-detail-sessionCheck').find('input[type="checkbox"]').eq(i).prop('checked')) {
							count += 1;
						}
					}
					if (!count) {
						flag = false;
					}
					$('.js-seminar-detail-agreement').find('input[type="checkbox"]').each(function (e) {
						if (!$('.js-seminar-detail-agreement').find('input[type="checkbox"]').eq(e).prop('checked')) {
							flag = false;
						}
					});
					if (flag) {
						$('.js-seminar-detail-submit').removeClass('_disable');
					} else {
						$('.js-seminar-detail-submit').addClass('_disable');
					}
				};

				;
				// 初期表示チェック
				valCheck();
				$('.js-seminar-detail-allCheck').find('input[type="checkbox"]').change(function () {
					valCheck();
				});
				$('.js-seminar-detail-sessionCheck').find('input[type="checkbox"]').change(function () {
					valCheck();
				});
				$('.js-seminar-detail-agreement').find('input[type="checkbox"]').change(function () {
					valCheck();
				});
			}
			// 詳細を開く・閉じる
			if ($('.seminar-moreBlock').length) {
				$('.seminar-moreBlock-button').on('click', function () {
					$(this).toggleClass(a);
					$('.seminar-moreBlock').toggleClass(a);
				});
			}
		},
		bookmark: function bookmark() {
			// ③取得API
			if ($('.js-bookmark-list').length) {
				var baseURL = '';
				_passURL = '/mypage/bookmark/list/';
				if (document.domain === 'review.cmsod.jp') {
					baseURL = 'https://staging.api.saponet.mynavi.jp';
				}
				$.ajax({
					method: 'get',
					url: baseURL + _passURL
				}).then(function (response) {
					console.log(response, '③取得APIresponse');
					var HTML = '';
					if (response.list.length) {
						if (12 < response.list.length) {
							$('.index-tab-more').removeClass('di_none');
						}
						response.list.forEach(function (listitem, listindex) {
							// console.log(listitem,'listitem');
							var __li = '';
							// console.log(listindex,'listindex');
							if (listindex < 12) {
								__li = '<li class="pure-u-1-3 pure-u-md-1-1 space-u-20-child js-bookmark-list-item is-active">';
							} else {
								__li = '<li class="pure-u-1-3 pure-u-md-1-1 space-u-20-child js-bookmark-list-item di_none">';
							}
							var __category = '';
							if (listitem.category == 1) {
								__category = '<p class="pos_relative"><span class="__label">記事・コラム</span></p>';
							} else if (listitem.category == 2) {
								__category = '<p class="pos_relative"><span class="__label">商品サービス（新卒）</span></p>';
							} else if (listitem.category == 3) {
								__category = '<p class="pos_relative"><span class="__label">商品サービス（中途）</span></p>';
							} else if (listitem.category == 4) {
								__category = '<p class="pos_relative"><span class="__label">セミナー</span></p>';
							} else if (listitem.category == 5) {
								__category = '<p class="pos_relative"><span class="__label">採用入門（新卒）</span></p>';
							} else if (listitem.category == 6) {
								__category = '<p class="pos_relative"><span class="__label">採用入門（中途）</span></p>';
							} else if (listitem.category == 7) {
								__category = '<p class="pos_relative"><span class="__label">お役立ち資料</span></p>';
							} else if (listitem.category == 8) {
								__category = '<p class="pos_relative"><span class="__label">見逃し配信</span></p>';
							}
							var __tag = '';
							if (listitem.tag.length) {
								listitem.tag.forEach(function (tagitem, tagindex) {
									__tag += '<span class=""><a href="' + tagitem.url + '" class="">#' + tagitem.label + '</a></span>';
								});
							}
							HTML += __li + '<div class="index-column-item">' + '<a href="' + listitem.url + '" class="di_block">' + '<figure class="__figure"><img class="width-u-100" src="' + listitem.img + '" alt="' + listitem.title + '" /></figure>' + '</a>' + '<div class="index-column-item-text">' + __category + '<h4 class="__title"><a href="' + listitem.url + '" class="di_block"><span class="">' + listitem.title + '</span></a></h4>' +
							// '<div class="__tag">'+
							// __tag+
							// '</div>'+
							'<button type="button" class="__bookmarkDay js-bookmark-remove">' + '<svg class="__bookmarkDay-svg"><use xlink:href="/common/svg/sprite.svg#bookmark" /></svg>' + '<span class="__bookmarkDay-text">ブックマーク登録日 <span>' + listitem.date.substr(0, 4) + '/' + listitem.date.substr(4, 2) + '/' + listitem.date.substr(6, 2) + '</span></span>' + '</button>' + '</div>' + '</div>' + '</li>';
						});
						$('.js-bookmark-list').append(HTML);
						setTimeout(function () {
							$('.index-column-item .__figure').matchHeight();
							$('.index-column-item .__title').matchHeight();
							$('.index-column-item-text').matchHeight();
						}, 500);
						// お気に入り削除
						$(document).on('click', '.js-bookmark-remove', function (e) {
							var __url = '?url=' + $(e.currentTarget).parents('.js-bookmark-list-item').find('a').eq(0).attr('href');
							var _passURL = '/mypage/bookmark/remove/';
							document.cookie = "_BKM_INF=" + encodeURI(__url) + "; path=/; SameSite=Lax; Max-Age=100; Secure";
							if (document.domain === 'review.cmsod.jp') {
								baseURL = 'https://staging.api.saponet.mynavi.jp';
								_passURL = '/mypage/bookmark/remove/' + __url;
							}
							$.ajax({
								method: 'get',
								url: baseURL + encodeURI(_passURL)
							}).then(function (response) {
								console.log(response, 'response');
								if (response.result == 0) {
									$(e.currentTarget).parents('.js-bookmark-list-item').remove();
									setTimeout(function () {
										$('.index-column-item .__figure').matchHeight();
										$('.index-column-item .__title').matchHeight();
										$('.index-column-item-text').matchHeight();
										// $('html,body').stop().animate({ scrollTop: 0 }, 1200, 'easeOutExpo');
									}, 200);
									// console.log($('.js-bookmark-list-item').length,'testes');
									if (!$('.js-bookmark-list-item').length) {
										HTML = '<li class="pure-u-1-1 pure-u-md-1-1 space-u-20-child">' + '<figure class="ta_center"><img class="width-u-10 width-lg-15 width-md-20" src="/common/images/bookmark.svg" alt="" /></figure>' + '<h4 class="fs_22 fs_md_18 ta_center mod-spacer">ブックマークの登録がありません</h4>' + '<p class="ta_center ta_md_left mt20">記事やセミナーなどで、「ブックマーク」ボタンをクリックすると、<br data-view="pc">この画面に保存されて見たいときにいつでも確認できます。</p>' + '<a href="/column/column_saiyo/" class="mod-button _blue _500 mod-spacer"><span>記事をブックマークしにいく</span></a>' + '<p class="fs_18 fs_md_16 fw_bold mod-spacer ta_center">複数のデバイスでブックマークを同期したい方</p>' + '<p class="ta_center ta_md_left mt20">会員登録（無料）後にブックマークいただくと<br data-view="pc">どのデバイスからでも同じブックマークを見ることができます。</p>' + '<p class="mod-spacer ta_center"><a href="https://saponet.mynavi.jp/account/login/" class="mod-link03 opacity "><span class="color-main">ログイン</span></a>｜<a href="https://saponet.mynavi.jp/account/create/" class="mod-link03 opacity color-main"><span class="color-main">新規会員登録（無料）</span></a></p>' + '</li>';
										$('.js-bookmark-list').append(HTML);
										setTimeout(function () {
											$('html,body').stop().animate({ scrollTop: 0 }, 1200, 'easeOutExpo');
										}, 200);
									}
									// ブックマークから削除しました
									$('body').append('<div class="js-bookmark-addremovetext" style="position: fixed;z-index: 10000;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #fff;width: 90%;max-width: 500px;text-align: center;padding: 20px;box-shadow: 0 5px 30px -10px rgba(0,0,0,.3);border-radius: 10px;">' + '<div class="ta_center"><svg class="" style="fill:#dcdcdc;" width="80" height="80"><use xlink:href="/common/svg/sprite.svg#bookmark"></use></svg></div>' + '<p class="mt10 fs_18 fs_md_16 fw_bold">ブックマークから削除しました</p></div>');
									setTimeout(function () {
										$('.js-bookmark-addremovetext').remove();
									}, 1500);
								} else if (response.result == 9) {
									console.log('9err');
								}
							}).catch(function (err) {
								console.log(err, 'err');
							});
						});
						// もっと見る
						$('.index-tab-more').on('click', function () {
							$('.js-bookmark-list-item.di_none').each(function (index, element) {
								if (index < 12) {
									$(element).addClass('is-active');
								}
							});
							$('.js-bookmark-list-item.is-active').removeClass('di_none');
							if (!$('.js-bookmark-list-item.di_none').length) {
								$('.index-tab-more').addClass('di_none');
							}
						});
					} else {
						HTML = '<li class="pure-u-1-1 pure-u-md-1-1 space-u-20-child">' + '<figure class="ta_center"><img class="width-u-10 width-lg-15 width-md-20" src="/common/images/bookmark.svg" alt="" /></figure>' + '<h4 class="fs_22 fs_md_18 ta_center mod-spacer">ブックマークの登録がありません</h4>' + '<p class="ta_center ta_md_left mt20">記事やセミナーなどで、「ブックマーク」ボタンをクリックすると、<br data-view="pc">この画面に保存されて見たいときにいつでも確認できます。</p>' + '<a href="/column/column_saiyo/" class="mod-button _blue _500 mod-spacer"><span>記事をブックマークしにいく</span></a>' + '<p class="fs_18 fs_md_16 fw_bold mod-spacer ta_center">複数のデバイスでブックマークを同期したい方</p>' + '<p class="ta_center ta_md_left mt20">会員登録（無料）後にブックマークいただくと<br data-view="pc">どのデバイスからでも同じブックマークを見ることができます。</p>' + '<p class="mod-spacer ta_center"><a href="https://saponet.mynavi.jp/account/login/" class="mod-link03 opacity "><span class="color-main">ログイン</span></a>｜<a href="https://saponet.mynavi.jp/account/create/" class="mod-link03 opacity color-main"><span class="color-main">新規会員登録（無料）</span></a></p>' + '</li>';
						$('.js-bookmark-list').append(HTML);
					}
				}).catch(function (err) {
					console.log(err, 'err');
				});
			}
			// ④チェックAPI
			if ($('.js-bookmark-btn').length) {
				var _baseURL = '';
				_passURL = '/mypage/bookmark/chk/';
				if (document.domain === 'review.cmsod.jp') {
					_baseURL = 'https://staging.api.saponet.mynavi.jp';
				}
				$.ajax({
					method: 'get',
					url: _baseURL + _passURL
				}).then(function (response) {
					console.log(response, '④チェックAPIresponse');
					if (response.list.length) {
						response.list.forEach(function (listitem, listindex) {
							if ($('.js-bookmark-detail').length) {
								if (location.pathname == listitem) {
									$('.js-bookmark-btn').addClass(a);
								}
							} else {
								$('.js-bookmark-btn').each(function (index, element) {
									if ($(element).parents('.js-bookmark').find('.js-bookmark-url').attr('href') == listitem) {
										$(element).addClass(a);
									}
								});
							}
						});
					}
				}).catch(function (err) {
					console.log(err, 'err');
				});
			}
			// ①登録API,②登録削除（解除）API
			$(document).on('click', '.js-bookmark-btn', function (e) {
				var _url = void 0;
				var _img = void 0;
				if ($('.js-bookmark-detail').length) {
					_url = '?url=' + location.pathname;
					_img = '&img=' + $(e.currentTarget).parents('.js-bookmark').find('.js-bookmark-img').text();
				} else {
					_url = '?url=' + $(e.currentTarget).parents('.js-bookmark').find('.js-bookmark-url').attr('href');
					_img = '&img=' + $(e.currentTarget).parents('.js-bookmark').find('.js-bookmark-img').attr('src');
				}
				var _title = '&title=' + $(e.currentTarget).parents('.js-bookmark').find('.js-bookmark-title').text();
				var _category = '&category=' + $(e.currentTarget).parents('.js-bookmark').find('.js-bookmark-category').text();
				var _passURL = '';
				var baseURL = '';
				if (!$(e.currentTarget).hasClass(a)) {
					// ①登録API
					console.log('①登録API');
					_passURL = '/mypage/bookmark/add/';
					document.cookie = "_BKM_INF=" + encodeURI(_url + _img + _title + _category) + "; path=/; SameSite=Lax; Max-Age=100; Secure";
					if (document.domain === 'review.cmsod.jp') {
						baseURL = 'https://staging.api.saponet.mynavi.jp';
						_passURL = '/mypage/bookmark/add/' + _url + _img + _title + _category;
					}
					console.log(_passURL, '①登録API');
					$.ajax({
						method: 'get',
						url: baseURL + encodeURI(_passURL)
					}).then(function (response) {
						console.log(response, '①登録APIresponse');
						if (response.result == 0) {
							$(e.currentTarget).addClass(a);
							// ブックマークに追加しました
							$('body').append('<div class="js-bookmark-addremovetext" style="position: fixed;z-index: 10000;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #fff;width: 90%;max-width: 500px;text-align: center;padding: 20px;box-shadow: 0 5px 30px -10px rgba(0,0,0,.3);border-radius: 10px;">' + '<div class="ta_center"><svg class="" style="fill:#42b6d4;" width="80" height="80"><use xlink:href="/common/svg/sprite.svg#bookmark"></use></svg></div>' + '<p class="mt10 fs_18 fs_md_16 fw_bold">ブックマークに追加しました</p></div>');
							setTimeout(function () {
								$('.js-bookmark-addremovetext').remove();
							}, 1500);
						} else if (response.result == 1) {
							var HTML = '<div class="mod-bookmark-modal-overlay">';
							HTML += '<div class="mod-bookmark-modal-closeArea"></div>' + '<span class="mod-bookmark-modal-closeBtn"><span></span></span>' + '<div class="mod-bookmark-modal-content"><div class="mod-bookmark-modal-content-in">' + '<svg class=""><use xlink:href="/common/svg/sprite.svg#notice" /></svg>' + '<p class="mt20 fs_18 fs_md_16 fw_bold">ブックマークできません</p>' + '<p class="mt20">保存は50件までです。<br>ブックマーク一覧から削除した後、<br>新規保存が可能です。</p>' + '<a href="/bookmark/" class="mod-button _blue _300 mt30"><span>ブックマーク一覧を見る<svg class="icon"><use xlink:href="/common/svg/sprite.svg#arrow_right"></use></svg></span></a>' + '</div></div>';
							$('body').append(HTML);
							var $overlay = $('.mod-bookmark-modal-overlay');
							$overlay.addClass(a);
							setTimeout(function () {
								$overlay.addClass(v);
								$('.mod-bookmark-modal-closeArea, .mod-bookmark-modal-closeBtn').on('click', function () {
									$overlay.removeClass(v);
									setTimeout(function () {
										$overlay.removeClass(a);
										$overlay.remove();
									}, 400);
								});
							});
						} else if (response.result == 9) {
							console.log('9err');
						}
					}).catch(function (err) {
						console.log(err, 'err');
					});
				} else {
					// ②登録削除（解除）API
					console.log('②登録削除（解除）API');
					_passURL = '/mypage/bookmark/remove/';
					document.cookie = "_BKM_INF=" + encodeURI(_url) + "; path=/; SameSite=Lax; Max-Age=100; Secure";
					if (document.domain === 'review.cmsod.jp') {
						baseURL = 'https://staging.api.saponet.mynavi.jp';
						_passURL = '/mypage/bookmark/remove/' + _url;
					}
					$.ajax({
						method: 'get',
						url: baseURL + encodeURI(_passURL)
					}).then(function (response) {
						console.log(response, '②登録削除（解除）APIresponse');
						if (response.result == 0) {
							$(e.currentTarget).removeClass(a);
							// ブックマークから削除しました
							$('body').append('<div class="js-bookmark-addremovetext" style="position: fixed;z-index: 10000;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #fff;width: 90%;max-width: 500px;text-align: center;padding: 20px;box-shadow: 0 5px 30px -10px rgba(0,0,0,.3);border-radius: 10px;">' + '<div class="ta_center"><svg class="" style="fill:#dcdcdc;" width="80" height="80"><use xlink:href="/common/svg/sprite.svg#bookmark"></use></svg></div>' + '<p class="mt10 fs_18 fs_md_16 fw_bold">ブックマークから削除しました</p></div>');
							setTimeout(function () {
								$('.js-bookmark-addremovetext').remove();
							}, 1500);
						} else if (response.result == 9) {
							console.log('9err');
						}
					}).catch(function (err) {
						console.log(err, 'err');
					});
				}
			});
		},
		glossary: function glossary() {
			if ($('.js-glossary-float').length) {
				var _scrollToObject = function _scrollToObject(_self, _speed) {
					var hash = '#' + _self.split('#')[1];
					var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

					if (hash !== '#pagetop') {
						if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
							pos -= 60;
						} else {
							pos -= 90;
						}
					}
					if (!(FUNCTIONS.va.window.width < 1024)) {
						$('.header-nav-content-li-in').removeClass(v);
						$('.header-nav-content-li_menu').stop().slideUp(400);
						$('.__block02').removeClass(v);
						$('.__block03').removeClass(v);
						$('.__block03').removeClass('is-visible2');
						$('.js-header-block01btn').removeClass(v);
						$('.js-header-block01content').removeClass(v);
						$('.js-header-block02btn').removeClass(v);
						$('.js-header-block02content').removeClass(v);
						$('.__member .js-toggle').removeClass(a);
						$('.__member .js-toggleBlock').css('display', 'none');
					}
					$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
				};

				var $float = $('.js-glossary-float');
				$(window).scroll(function (e) {
					var $this = $(e.currentTarget);
					var $floatWrap = $('.js-glossary-floatWrap');
					var $floatWrapPos = $floatWrap.offset().top - $('.headerWrap').height();
					var $footerpos = $('.mod-banner').offset().top - $float.outerHeight() - $('.mod-banner').outerHeight(true);
					var thisScroll = $this.scrollTop();
					// console.log(thisScroll,$footerpos);
					if (thisScroll > $floatWrapPos) {
						$float.addClass(a);
					} else {
						$float.removeClass(a);
					}
					if (thisScroll > $footerpos) {
						$float.addClass(v);
					} else {
						$float.removeClass(v);
					}
				});
				$(document).on('click', '.js-glossary-float-btn', function (e) {
					if (!$(e.currentTarget).hasClass(a)) {
						$(e.currentTarget).addClass(a);
						var HTML = '<div class="glossary-float-modal-overlay">';
						HTML += '<div class="glossary-float-modal-closeArea"></div>' + '<div class="glossary-float-modal-content"><span class="glossary-float-modal-closeBtn"><span></span></span>' + '<div class="glossary-float-modal-content-in">' + $('.js-glossary-float').html() + '</div></div></div>';
						$('body').append(HTML);
					} else {
						$(e.currentTarget).removeClass(a);
					}
				});
				$(document).on('click', '.glossary-float-modal-closeArea', function (e) {
					$float.removeClass(a);
					var $overlay = $('.glossary-float-modal-overlay');
					$overlay.remove();
				});
				$(document).on('click', '.glossary-float-modal-closeBtn', function (e) {
					$float.removeClass(a);
					var $overlay = $('.glossary-float-modal-overlay');
					$overlay.remove();
				});
				$(document).on('click', '.glossary-float-modal-content-in a[rel="scroll"]', function (event) {
					$float.removeClass(a);
					var $overlay = $('.glossary-float-modal-overlay');
					$overlay.remove();
					var speed = 1200;
					if (/#/.test(event.currentTarget.href)) {
						_scrollToObject(event.currentTarget.href, speed);
					}
					return false;
				});
			}
		},
		columndetailbnr: function columndetailbnr() {
			if ($('._bn_id_').length) {
				// クラス「_bn_id_」のある要素を列挙してそれぞれAPIコール
				$("._bn_id_").each(function (i, e) {
					// $.ajax({ url: 'https://staging.api.saponet.mynavi.jp/contact/bn/' + $(e).text() + '/' }).then(function (response) {
					$.ajax({ url: 'https://saponet.mynavi.jp/contact/bn/' + $(e).text() + '/' }).then(function (response) {
						// このAPIのレスポンスは下記をブラウザアクセスして確認可能です
						// https://staging.api.saponet.mynavi.jp/contact/bn/5/

						var req_id = $(e).text();
						if (response.code != 0) {
							// code=0以外（表示期間外など）はhide()しています
							// ブロック制御の関係で余計なスペースが開いてしまう場合は調整をお願いします
							$(e).html("");
							$(e).hide();
						} else {
							// code=0の場合にresultで置き換えます
							$(e).html(response.result);
							$(e).show();

							// 上記レスポンス内のaタグに押下イベントを設定(aタグが複数の可能性を考慮,class:_spbc_N)
							$("._spbc_" + req_id).each(function (i2, e2) {
								$(e2).click(function () {
									// $.ajax({ url: 'https://staging.api.saponet.mynavi.jp/contact/bnl/' + req_id + '/' }).then(function (response) {
									$.ajax({ url: 'https://saponet.mynavi.jp/contact/bnl/' + req_id + '/' }).then(function (response) {
										var a = document.createElement('a');
										document.body.appendChild(a);
										a.style.display = "none";
										var a_target = $(e2).attr('target');
										if (a_target) a.target = a_target;
										a.href = $(e2).attr('href');
										a.click();
										console.log(a);
									});
									// ajax送信をするためにaタグのhrefには遷移しないようfalseを返す
									return false;
								});
							});
						}
					});
				});
			}
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.pageTop();
			_this.headerMenu();
			_this.headerMegaMene();
			_this.search();
			_this.sideNav();
			_this.jsMatchHeight();
			_this.haederMypageMenu();
			_this.contactFloatLink();
			_this.marsflag();
			_this.tooltip();
			_this.seminarDetailPage();
			_this.bookmark();
			_this.glossary();
			_this.columndetailbnr();
			svg4everybody();
		}
	};

	$(function () {
		return FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript();
	});
})(window.jQuery);
$(window).on('load', function () {
	var easeScrollHash = function easeScrollHash() {
		if (location.hash) {
			var hash = '#' + location.hash.split('#')[1];
			var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;
			if (hash !== '#pagetop') {
				if ($(window).width() < 1024) {
					pos -= 60;
				} else {
					pos -= 90;
				}
			}
			$('html,body').stop().animate({ scrollTop: pos }, 400, 'easeOutExpo');
			if ($('.faq-toggleWrap').length) {
				$(hash).find('.js-toggle').toggleClass('is-active');
				$(hash).find('.js-toggle').next('.js-toggleBlock').stop().slideToggle(400);
			}
		}
	};
	easeScrollHash();
});